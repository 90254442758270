import i18n from "i18next"
import es from "./translations/es.json"
import en from "./translations/en.json"

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  }
}

// const userLang = navigator.language || navigator.userLanguage;
const lang = "en"
// userLang.split("-")[0] === "es" ? "es" :

i18n.init({
  resources,
  debug: true,
  lng: localStorage.intl || lang,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
})

export default i18n
