// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import selectedRegistrationStations from './reducers/registrationStation'
import verifiedPersons from './reducers/scanner'
import camera from './reducers/camera'

const rootReducer = {
  auth,
  navbar,
  layout,
  selectedRegistrationStations,
  verifiedPersons,
  camera
}

export default rootReducer
