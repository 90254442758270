import { SET_CAMERA_DEVICE } from "../constants"

const initialState = {
  cameraDevice: { value: "", label: "" }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMERA_DEVICE: {
      return { ...state, cameraDevice: action.payload }
    }
    default:
      return state
  }
}