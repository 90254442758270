import { CLEAR_VERIFIED_PERSON, VERIFIED_PERSON } from "../constants"

const initialState = {
  data: {}
}

export default function verifiedPerson(state = initialState, action) {
  switch (action.type) {
    case VERIFIED_PERSON:
      return {
        ...state,
        data: action.payload
      }
    case CLEAR_VERIFIED_PERSON:
      return {
        ...state,
        data: {}
      }

    default:
      return state
  }
}
